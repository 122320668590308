import makeValidationSchema from './GoodsDetailsMakeValidationSchema'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import { components, useTranslation } from 'cng-web-lib'
import { Grid, Divider, Typography, Tooltip } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import CngSection from 'src/components/cngcomponents/CngSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import DangerousGoodsEntry from './DangerousGoodsEntry'
import DangerousGoodsFormProperties from './DangerousGoodsFormProperties'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngIconButton },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  goodsDescription: "",
  noOfPackages: "",
  noOfPackagesUom: "",
  grossWeight: "",
  grossWeightUom: "",
  monetaryAmount: "",
  countryOfOrigin: "",
  harmonizedCode1: "",
  harmonizedCode2: "",
  harmonizedCode3: "",
  harmonizedCode4: "",
  harmonizedCode5: "",
  c4Code1: "",
  c4Code2: "",
  c4Code3: "",
  c4Code4: "",
  c4Code5: "",
  marksNo1: "",
  marksNo2: "",
  marksNo3: "",
  marksNo4: "",
  marksNo5: "",
  vin1: "",
  vin2: "",
  vin3: "",
  vin4: "",
  vin5: "",
  vin6: "",
  vin7: "",
  vin8: "",
  vin9: "",
  vin10: "",
  vin11: "",
  vin12: "",
  vin13: "",
  vin14: "",
  vin15: "",
  vin16: "",
  vin17: "",
  vin18: "",
  vin19: "",
  vin20: "",
  dangerousGoods: [],
  shipmentType:""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  shipmentTypeState,
  entryTypeState,
  masterShipment
}) {
  const { setValue, getValues, watch } = useFormContext()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const shipmentType = watch('shipmentType')
  const dangerousGoods = watch('dangerousGoods')
  const vinNos = watch([
    'vin1',
    'vin2',
    'vin3',
    'vin4',
    'vin5',
    'vin6',
    'vin7',
    'vin8',
    'vin9',
    'vin10',
    'vin11',
    'vin12',
    'vin13',
    'vin14',
    'vin15',
    'vin16',
    'vin17',
    'vin18',
    'vin19',
    'vin20'
  ])
  const [vinCount, setVinCount] = useState(vinNos.filter((vin) => vin).length)
  const marksNos = watch([
    'marksNo1',
    'marksNo2',
    'marksNo3',
    'marksNo4',
    'marksNo5'
  ])
  const htsList = watch([
    'harmonizedCode1',
    'harmonizedCode2',
    'harmonizedCode3',
    'harmonizedCode4',
    'harmonizedCode5'
  ])

  useEffect(() => {
    setValue('shipmentType', shipmentTypeState)

    if (
      shipmentTypeState !== 'SEC 321' &&
      shipmentTypeState !== 'IN-BOND' &&
      shipmentTypeState !== 'ASTRAY'
    ) {
      setValue('monetaryAmount', '')
    }
    if (shipmentTypeState !== 'SEC 321') {
      setValue('countryOfOrigin', '')
    }
  }, [shipmentTypeState])

  /**
   * To move values in middle of vin numbers
   * Example:
   * From { vin1: '', vin2: '123', vin3: '' }
   * To { vin1: '123', vin2: '', vin3: '' }
   */
  useEffect(() => {
    const result = vinNos.filter((vin) => vin !== '')

    for (let i = 0; i < 20; i++) {
      setValue(`vin${i + 1}`, result[i] || '')
    }
  }, [])

  function handleClearVinNos() {
    for (let i = 0; i < 20; i++) {
      setValue(`vin${i + 1}`, '')
    }
  }

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);


  function makeTranslatedTextsObject() {
    let goodsDetails = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.TITLE
    )
    let goodsDescription = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GOODS_DESCRIPTION
    )
    let noOfPackages = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES
    )
    let noOfPackagesUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES_UOM
    )
    let grossWeight = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT_UOM
    )
    let monetaryAmount = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MONETARY_AMOUNT
    )
    let countryOfOrigin = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.COUNTRY_OF_ORIGIN
    )

    let harmonizedCodeTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE_TITLE
    )

    let harmonizedCode1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE1
    )
    let harmonizedCode2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE2
    )
    let harmonizedCode3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE3
    )
    let harmonizedCode4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE4
    )
    let harmonizedCode5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE5
    )

    let c4CodeTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE_TITLE
    )

    let c4Code1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE1
    )
    let c4Code2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE2
    )
    let c4Code3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE3
    )
    let c4Code4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE4
    )
    let c4Code5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE5
    )

    let marksNoTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO_TITLE
    )

    let marksNo1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO1
    )
    let marksNo2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO2
    )
    let marksNo3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO3
    )
    let marksNo4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO4
    )
    let marksNo5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO5
    )

    let vinTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN_TITLE
    )

    let vin1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN1
    )
    let vin2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN2
    )
    let vin3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN3
    )
    let vin4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN4
    )
    let vin5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN5
    )
    let vin6 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN6
    )
    let vin7 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN7
    )
    let vin8 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN8
    )
    let vin9 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN9
    )
    let vin10 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN10
    )
    let vin11 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN11
    )
    let vin12 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN12
    )
    let vin13 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN13
    )
    let vin14 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN14
    )
    let vin15 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN15
    )
    let vin16 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN16
    )
    let vin17 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN17
    )
    let vin18 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN18
    )
    let vin19 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN19
    )
    let vin20 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN20
    )

    let hideText = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.HIDE_TEXT
    )

    let showText = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHOW_TEXT
    )

    let footerText = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.FOOTER_TEXT
    )

    let dangerousGoods = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.TITLE
    )
    let _goodsDetailsId = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.GOODS_DETAILS
    )
    let undgCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CODE
    )
    let undgContactName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CONTACT_NO
    )
    return {
      goodsDetails,
      goodsDescription,
      noOfPackages,
      noOfPackagesUom,
      grossWeight,
      grossWeightUom,
      monetaryAmount,
      countryOfOrigin,
      harmonizedCodeTitle,
      harmonizedCode1,
      harmonizedCode2,
      harmonizedCode3,
      harmonizedCode4,
      harmonizedCode5,
      c4CodeTitle,
      c4Code1,
      c4Code2,
      c4Code3,
      c4Code4,
      c4Code5,
      marksNoTitle,
      marksNo1,
      marksNo2,
      marksNo3,
      marksNo4,
      marksNo5,
      vinTitle,
      vin1,
      vin2,
      vin3,
      vin4,
      vin5,
      vin6,
      vin7,
      vin8,
      vin9,
      vin10,
      vin11,
      vin12,
      vin13,
      vin14,
      vin15,
      vin16,
      vin17,
      vin18,
      vin19,
      vin20,
      hideText,
      showText,
      footerText,
      dangerousGoods,
      undgCode,
      undgContactName,
      undgContactNo
    }
  }

  function handleAddDangerousGoods(data) {
    const dangerousGoods = [...getValues('dangerousGoods'), data].map(
      (material, index) => ({ ...material, _id: index + 1 })
    )

    setValue('dangerousGoods', dangerousGoods)
  }

  function handleDeleteDangerousGoods(data) {
    const clonedDangerousGoods = [...getValues('dangerousGoods')].filter((material) =>
      material.id ? material.id !== data.id : material._id !== data._id
    )
    const dangerousGoods = clonedDangerousGoods.map(
      (material, index) => ({ ...material, _id: index + 1 })
    )
    setValue('dangerousGoods', dangerousGoods)
  }

  function handleDuplicateVin(fieldName) {
    if (vinCount + 1 <= 20) {
      setValue(`vin${vinCount + 1}`, getValues(fieldName))
    }

    setVinCount((prev) => prev + 1)
  }

  function handleRemoveVin(removedIndex) {
    // Skips when removed index is the last index
    if (removedIndex !== vinCount) {
      for (let i = removedIndex; i <= vinCount; i++) {
        // Replace removed's values with the next value
        setValue(`vin${i}`, getValues(`vin${i + 1}`))
      }
    }

    // Remove last vin's values
    setValue(`vin${vinCount}`, '')

    setVinCount((prev) => prev - 1)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title='Classification &amp; weight'
            subheader={
              <Typography color="textSecondary" component="span" variant="caption">
                <Typography color="error" component="span" variant="inherit">*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={3} />
                  <Grid item xs={12} md={9}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} shouldHide={shouldHideMap.goodsDescription}>
                        <CngTextField
                          required
                          name='goodsDescription'
                          label={translatedTextsObject.goodsDescription}
                          disabled={disabled}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                          onBlur={(e) => {
                            if (e.target.value !== undefined) {
                              setValue('goodsDescription', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                            }
                          }}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.noOfPackages}>
                        <CngTextField
                          required
                          name='noOfPackages'
                          label={masterShipment === true ? 'Boarded Quantity' : translatedTextsObject.noOfPackages}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.noOfPackagesUom}>
                        <CngCodeMasterAutocompleteField
                          required
                          name='noOfPackagesUom'
                          label={translatedTextsObject.noOfPackagesUom}
                          disabled={disabled}
                          codeType='ACE_HW_UOM'
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeight}>
                        <CngTextField
                          required
                          name='grossWeight'
                          label={translatedTextsObject.grossWeight}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeightUom}>
                        <CngCodeMasterAutocompleteField
                          required
                          name='grossWeightUom'
                          label={translatedTextsObject.grossWeightUom}
                          disabled={disabled}
                          codeType='ACE_HW_GROSS_WEIGHT_UOM'
                          size='small'
                        />
                      </CngGridItem>
                      {shipmentType !== null && (
                        <>
                          {(shipmentType === 'SEC 321' ||
                            shipmentType === 'IN-BOND' ||
                            shipmentType === 'ASTRAY') && (
                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.monetaryAmount}>
                              <CngTextField
                                required
                                name='monetaryAmount'
                                label={translatedTextsObject.monetaryAmount}
                                disabled={disabled}
                                size='small'
                              />
                            </CngGridItem>
                          )}
                          {shipmentType === 'SEC 321' && (
                            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryOfOrigin}>
                              <CngCountryAutocompleteField
                                required
                                name='countryOfOrigin'
                                label={translatedTextsObject.countryOfOrigin}
                                disabled={disabled}
                                size='small'
                              />
                            </CngGridItem>
                          )}
                          {shipmentType === 'BRASS' && (
                            <CngGridItem xs={12}>
                              <CollapsibleSection
                                title={translatedTextsObject.c4CodeTitle}
                                footerText='Additional Information'
                                defaultExpanded={true}
                              >
                                <Grid container spacing={2}>
                                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.c4Code1}>
                                    <CngTextField
                                      required
                                      name='c4Code1'
                                      label={translatedTextsObject.c4Code1}
                                      disabled={disabled}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      onBlur={(e) => {
                                        if (e.target.value !== undefined) {
                                          setValue('c4Code1', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                        }
                                      }}
                                      size='small'
                                    />
                                  </CngGridItem>
                                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.c4Code2}>
                                    <CngTextField
                                      name='c4Code2'
                                      label={translatedTextsObject.c4Code2}
                                      disabled={disabled}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      onBlur={(e) => {
                                        if (e.target.value !== undefined) {
                                          setValue('c4Code2', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                        }
                                      }}
                                      size='small'
                                    />
                                  </CngGridItem>
                                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.c4Code3}>
                                    <CngTextField
                                      name='c4Code3'
                                      label={translatedTextsObject.c4Code3}
                                      disabled={disabled}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      onBlur={(e) => {
                                        if (e.target.value !== undefined) {
                                          setValue('c4Code3', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                        }
                                      }}
                                      size='small'
                                    />
                                  </CngGridItem>
                                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.c4Code4}>
                                    <CngTextField
                                      name='c4Code4'
                                      label={translatedTextsObject.c4Code4}
                                      disabled={disabled}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      onBlur={(e) => {
                                        if (e.target.value !== undefined) {
                                          setValue('c4Code4', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                        }
                                      }}
                                      size='small'
                                    />
                                  </CngGridItem>
                                  <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.c4Code5}>
                                    <CngTextField
                                      name='c4Code5'
                                      label={translatedTextsObject.c4Code5}
                                      disabled={disabled}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      onBlur={(e) => {
                                        if (e.target.value !== undefined) {
                                          setValue('c4Code5', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                        }
                                      }}
                                      size='small'
                                    />
                                  </CngGridItem>
                                </Grid>
                              </CollapsibleSection>
                            </CngGridItem>
                          )}
                        </>
                      )}
                      {entryTypeState != null &&
                        (entryTypeState == '62' || entryTypeState == '63' || entryTypeState == '70') && (
                          <CngGridItem xs={12}>
                            <CollapsibleSection
                              title={translatedTextsObject.harmonizedCodeTitle}
                              footerText='Additional Information'
                            defaultExpanded={htsList.filter(hts => hts).length > 0 || (entryTypeState == '62' || entryTypeState == '63' || entryTypeState == '70')}
                            >
                              <Grid container spacing={2}>
                                <CngGridItem     xs={12} sm={6} shouldHide={shouldHideMap.harmonizedCode1}>
                                  <CngTextField
                                  required
                                    name='harmonizedCode1'
                                    label={translatedTextsObject.harmonizedCode1}
                                    disabled={disabled}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    onBlur={(e) => {
                                      if (e.target.value !== undefined) {
                                        setValue('harmonizedCode1', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                      }
                                    }}
                                    size='small'
                                  />
                                </CngGridItem>
                                <CngGridItem     xs={12} sm={6} shouldHide={shouldHideMap.harmonizedCode2}>
                                  <CngTextField
                                    name='harmonizedCode2'
                                    label={translatedTextsObject.harmonizedCode2}
                                    disabled={disabled}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    onBlur={(e) => {
                                      if (e.target.value !== undefined) {
                                        setValue('harmonizedCode2', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                      }
                                    }}
                                    size='small'
                                  />
                                </CngGridItem>
                                <CngGridItem     xs={12} sm={6} shouldHide={shouldHideMap.harmonizedCode3}>
                                  <CngTextField
                                    name='harmonizedCode3'
                                    label={translatedTextsObject.harmonizedCode3}
                                    disabled={disabled}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    onBlur={(e) => {
                                      if (e.target.value !== undefined) {
                                        setValue('harmonizedCode3', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                      }
                                    }}
                                    size='small'
                                  />
                                </CngGridItem>
                                <CngGridItem     xs={12} sm={6} shouldHide={shouldHideMap.harmonizedCode4}>
                                  <CngTextField
                                    name='harmonizedCode4'
                                    label={translatedTextsObject.harmonizedCode4}
                                    disabled={disabled}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    onBlur={(e) => {
                                      if (e.target.value !== undefined) {
                                        setValue('harmonizedCode4', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                      }
                                    }}
                                    size='small'
                                  />
                                </CngGridItem>
                                <CngGridItem     xs={12} sm={6} shouldHide={shouldHideMap.harmonizedCode5}>
                                  <CngTextField
                                    name='harmonizedCode5'
                                    label={translatedTextsObject.harmonizedCode5}
                                    disabled={disabled}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    onBlur={(e) => {
                                      if (e.target.value !== undefined) {
                                        setValue('harmonizedCode5', e.target.value.trim().toUpperCase(), { shouldValidate: true })
                                      }
                                    }}
                                    size='small'
                                  />
                                </CngGridItem>
                              </Grid>
                            </CollapsibleSection>
                          </CngGridItem>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CollapsibleSection
                  defaultExpanded={dangerousGoods.length > 0}
                  onExpandedChange={(expanded) => {
                    const { initialValues } = DangerousGoodsFormProperties.formikProps
                    setValue('dangerousGoods', expanded ? [{ ...initialValues, _id: 1 }] : [])
                  }}
                  title={translatedTextsObject.dangerousGoods}
                >
                  <Grid container spacing={1}>
                    {dangerousGoods.map((goods, index) => {
                      const isLast = dangerousGoods.length === 1

                      return (
                        <React.Fragment key={goods._id || goods.id || index}>
                          <Grid item xs={12}>
                            <DangerousGoodsEntry
                              index={index}
                              isLast={isLast}
                              onAddDangerousGoods={handleAddDangerousGoods}
                              onDeleteDangerousGoods={handleDeleteDangerousGoods}
                              dangerousGoods={goods}
                              showNotification={showNotification}
                            />
                          </Grid>
                          {dangerousGoods.length !== index + 1 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </CollapsibleSection>
              </Grid>
              <Grid item xs={12}>
                <CollapsibleSection
                  defaultExpanded={vinCount > 0}
                  onExpandedChange={(expanded) => {
                    if (expanded) {
                      setVinCount(1)
                    } else {
                      handleClearVinNos()
                    }
                  }}
                  title={translatedTextsObject.vinTitle}
                >
                  <Grid container spacing={2}>
                    {vinCount > 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          {[...Array(vinCount)]
                            .map((_, index) => index + 1)
                            .map((count) => {
                              const fieldName = `vin${count}`

                              return (
                                <React.Fragment key={fieldName}>
                                  <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                      <CngGridItem xs={12} sm shouldHide={shouldHideMap[fieldName]}>
                                        <CngTextField
                                          name={fieldName}
                                          label={translatedTextsObject[fieldName]}
                                          disabled={disabled}
                                          size='small'
                                        />
                                      </CngGridItem>
                                      <CngGridItem xs={12} sm='auto'>
                                        <Grid container spacing={1}>
                                          {vinCount < 20 && (
                                            <>
                                              <Grid item xs='auto'>
                                                <Tooltip placement='bottom' title='Add new'>
                                                  <span>
                                                    <CngIconButton
                                                      icon={['fal', 'plus']}
                                                      onClick={() => setVinCount((prev) => prev + 1)}
                                                      size='small'
                                                      type='outlined'
                                                    />
                                                  </span>
                                                </Tooltip>
                                              </Grid>
                                              <Grid item xs='auto'>
                                                <Tooltip placement='bottom' title='Add new'>
                                                  <span>
                                                    <CngIconButton
                                                      icon={['fal', 'copy']}
                                                      onClick={() => handleDuplicateVin(fieldName)}
                                                      size='small'
                                                      type='outlined'
                                                    />
                                                  </span>
                                                </Tooltip>
                                              </Grid>
                                            </>
                                          )}
                                          {vinCount > 1 && (
                                            <Grid item xs='auto'>
                                              <Tooltip placement='bottom' title='Add new'>
                                                <span>
                                                  <CngIconButton
                                                    icon={['fal', 'trash']}
                                                    onClick={() => handleRemoveVin(count)}
                                                    size='small'
                                                    type='outlined'
                                                  />
                                                </span>
                                              </Tooltip>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </CngGridItem>
                                    </Grid>
                                  </Grid>
                                  {vinCount !== count  && (
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  )}
                                </React.Fragment>
                              )
                            })}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </CollapsibleSection>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={marksNos.filter(marksNo => marksNo).length > 0}
            title={translatedTextsObject.marksNoTitle}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksNo1}>
                <CngTextField
                  name='marksNo1'
                  label={translatedTextsObject.marksNo1}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksNo2}>
                <CngTextField
                  name='marksNo2'
                  label={translatedTextsObject.marksNo2}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksNo3}>
                <CngTextField
                  name='marksNo3'
                  label={translatedTextsObject.marksNo3}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksNo4}>
                <CngTextField
                  name='marksNo4'
                  label={translatedTextsObject.marksNo4}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.marksNo5}>
                <CngTextField
                  name='marksNo5'
                  label={translatedTextsObject.marksNo5}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
    </>
  )
}

const GoodsDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default GoodsDetailsFormProperties
